<template>
  <div>
    <section class="careers-head">
      <h1 class="section-title">{{ $t("careersPage.workWithUs") }}</h1>
      <div class="container">
        <div class="careers-head-content">
          {{ $t("careersPage.trebelIsAVentureBacked") }}
        </div>
      </div>
    </section>
    <section class="careers-the-trebel-way">
      <div class="container">
        <div class="careers-the-trebel-way-content">
          <b-row>
            <b-col md="4"
              ><h3>{{ $t("careersPage.theTrebelWay") }}</h3></b-col
            >
            <b-col md="8">{{ $t("careersPage.weLoveOurUsers") }}</b-col>
          </b-row>
        </div>
      </div>
    </section>
    <section class="careers-opportunities">
      <h2 class="section-title">
        {{ $t("careersPage.careersOpportunities") }}
      </h2>
      <div class="careers-opportunities-content">
        <div v-for="(job, index) in jobs" class="job-item" :key="index">
          <div class="job-department">{{ job.department }}</div>
          <h3 class="job-title">{{ job.title }}</h3>
          <p class="job-description">{{ job.description }}</p>
          <div class="job-location">{{ job.location }}</div>
          <div class="job-salary">{{ job.salary }}</div>
          <div class="form-group text-right">
            <b-link class="btn-minimal" :to="`${locale.base}jobs/${index}`">{{
              $t("careersPage.learnMore")
            }}</b-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("meta.careers.title"),
      titleTemplate: null,
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: this.$t("meta.careers.title"),
        description: this.$t("meta.careers.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  data() {
    return {
      jobs: {}
    };
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  },
  mounted() {
    if (this.$i18n.locale === "mx") {
      this.$router.push({ name: "Home" });
    }

    this.jobs = this.$t("jobs");
  }
};
</script>
